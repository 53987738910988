<template>
  <div class="flex flex-col items-center justify-start group">
    <div class="relative w-full">


      <div class="group">
        <div class="rounded-lg relative">
          <div class="bg-black/10 backdrop-blur-lg font-bold rounded-md text-black absolute top-8 z-20 text-xs py-1 px-2 m-2" v-if="selectedVariant?.isNew">
            {{ $t('app.new') }}
          </div>

          <DiscountPercent :selected-variant="selectedVariant" v-if="!showRateNextToPrice"/>

          <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="block z-10" @mouseenter="hoverImage=true" @mouseleave="hoverImage=false">
            <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
              <span class="product-out-of-stock"><StockOutText /></span>
            </div>

            <div
              class="flex absolute z-20 justify-center text-center items-end w-full h-full"
              v-if="selectedVariant?.isFreeShipping"
            >
              <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                <BoltIcon class="h-5 w-5 mr-2 fill-primary" />
                <span>{{ $t('app.free-shipping') }}</span>
              </span>
            </div>

            <NuxtImg
              v-if="sourceImage && !isSlider"
              width="600"
              height="900"
              fit="outside"
              loading="lazy"
              :placeholder="[50, 50, 93, 3]"
              :quality="90"
              :alt="selectedVariant?.title"
              :class="{ 'product-img-out-of-stock': !selectedVariant?.inStock }"
              class="z-1 object-contain"
              :src="sourceImage"
            />

            <NuxtImg
              v-else-if="sourceImage && isSlider"
              width="600"
              height="900"
              fit="outside"
              loading="lazy"
              :placeholder="[50, 50, 93, 3]"
              :quality="90"
              :alt="selectedVariant?.title"
              :class="{ 'product-img-out-of-stock': !selectedVariant?.inStock }"
              class="z-1 object-contain aspect-square"
              :src="image"
            />
            <NuxtImg
              v-else
              fit="outside"
              alt=""
              class="z-1 object-contain"
              width="368"
              height="552"
              src="/images/no-image.png"
            />
          </NuxtLinkLocale>

          <!-- Ürün Seçenekleri -->
          <SectionProductCardGroupOptions :product="product" />

        </div>

        <div class="flex flex-col text-base text-primary mt-2" v-if="!isSlider">
          <div class="card-brand" v-if="!hideBrand">
            {{ product.brandName }}
          </div>
          <div class="line-clamp-2">
            {{ selectedVariant?.title }}
          </div>

          <!-- Fiyat Bilgisi -->
          <SectionProductCardPriceView :variant="selectedVariant" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { HeartIcon, BoltIcon } from "@heroicons/vue/24/outline";
import { HeartIcon as HeartFilledIcon } from "@heroicons/vue/24/solid";
import TriangleIcon from "assets/icons/triangle.svg";
import AddToCartIcon from "assets/icons/add-to-cart.svg";

import { collect } from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";

const props = defineProps(["product", "selectedWarehouse", "isSlider", "image"]);
const emit = defineEmits(['setImage', 'setHoverImage'])

const storeConfig = useStoreConfig()
const { baseConfig, showRateNextToPrice } = storeToRefs(storeConfig)

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

// Ön tanımlı ürün görseli
const sourceImage = useState(`selectedVariantImage::${props.product.productId}::${props.product.productGroupValue}`);

const hoverImage = ref(`selectedVariantImageIsHover::${props.product.productId}::${props.product.productGroupValue}`);

const {selectedWarehouse, hideBrand} = useListing()

const variants = await collect(props.product.variants);

const { addToBasket, addCartLoading } = await useBasket();
const { isWholesaleUser, authModalIsVisible } = await useCustomer();
const { hidePricesForGuest } = useHelper();
const { addWishlist } = useWishlist();

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>
